:root {
  --menu:#2a303d;--menu_off:#4d586e;
  --font: #363636;
  --track: #3B4894;--thumb: #a4acd8;
  --btn_on: #3B4894;--btn_on_color: #fff;
  --btn_off: #ccc;--btn_off_color: #363636;
  --warning:#f9b115;
  --danger:#e23737;
  --success:#0d9932;
  --info:#2490fc;
}

html{-ms-content-zooming: none; -ms-touch-action: pan-x pan-y; background-color:#f3f4f7}
html,body{font-size:14px;-webkit-text-size-adjust:100%;background-color:#f3f4f7}
/*body {-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}*/
body,div,p,h1,h2,h3,h4,h5,h6,ul,dl,dt,dd,li,ol,span,strong,li,ul,dl,dd,dt,table,tr,td,th,tbody,tfoot,a,button,form,textarea,
input,img,header,footer,section,small,label,em,fieldset,caption,select{box-sizing: border-box;font-family: 'sans-serif';color:var(--font);outline: unset;margin:0;padding:0;font-weight:500;font-style:normal;letter-spacing:0}
h1,h2,h3,h4,h5,h6{font-weight:700}
.btns{margin-top:20px}
.btns button{margin:0 5px;font-size:14px}
.ck.ck-editor__main>.ck-editor__editable{height:600px}
.ckmini .ck.ck-editor__main>.ck-editor__editable{height:400px}
ul,dl{list-style-type:none;}
a{text-decoration:none;cursor:pointer;}
button{background-color:transparent;border:0;cursor:pointer;color:var(--btn_on_color);height:40px;padding:0 30px}
table{border-spacing:0;border-collapse:collapse;}
input,button{appearance: none;-moz-appearance: none;-o-appearance: none;border:none;-webkit-appearance: none;border-radius:5px;}
input:autofill,input:autofill:hover,input:autofill:focus,input:autofill:active {-webkit-text-fill-color: var(--font);-webkit-box-shadow: 0 0 0px 1000px #ddd inset;box-shadow: 0 0 0px 1000px #ddd inset;transition: background-color 5000s ease-in-out 0s;}
input::placeholder,textarea::placeholder{color:#b6b6b6}
.attach button{display:block;margin-bottom:5px;}

select{width:100%;height:40px;border-radius:5px;border:1px solid #ccc;padding:0 10px}
caption{font-size:30px;font-weight:700;margin-bottom:20px;text-align:center;}
caption span{vertical-align: top;font-size:30px;font-weight:700;margin-left:10px;color:var(--btn_on)}
.head{font-size:18px;text-align:left;margin:10px 0 5px 0;display:block;width:100%;height:30px;}
.head span{margin-left:15px;font-size:13px;}
.head strong{font-weight:700;margin-left:5px;font-size:15px}
.head button{float:right;margin-right:5px;}
.head button:first-child{margin-right:0}
.head a{float:right;margin-right:5px;}
.head a:first-child{margin-right:0}

.menu::-webkit-scrollbar {width: 4px;}
.header::-webkit-scrollbar {height: 4px;}
.menu::-webkit-scrollbar-thumb,.header::-webkit-scrollbar-thumb {background-color: var(--track);}
.menu::-webkit-scrollbar-track,.header::-webkit-scrollbar-track {background-color: var(--thumb);}

.menu{position:fixed;left:0;top:0;width:260px;height:100%;background-color:var(--menu);color:var(--btn_on_color);overflow:hidden;overflow-y:auto;z-index:2;}
.menu h1{font-size:26px;font-weight:700;color:var(--btn_on_color);padding:12px 10px 0 20px;border-bottom:1px solid var(--track);height:60px}
.menu h1 img{vertical-align: top;}
.menu dl{padding:0 10px;margin:10px 0;}
.menu dt{display:none;color:var(--thumb);height:60px;font-size:12px;line-height:90px;padding:0 0 0 20px;}
.menu a{color:var(--btn_on_color);min-height:40px;display:block;padding:0 0 0 20px;line-height:40px;width:100%;border-radius:5px;position:relative}
.menu a:hover{background-color:var(--menu_off)}
.menu li {margin:5px 0}
.menu li a:before{content:'●';font-size:4px;position:absolute;left:40px;top:1px;}
.menu li a{padding-left:53px;position:relative}
.menu li.active a{background-color:var(--btn_on)}
.menu li small{color:var(--danger);position:absolute;right:15px;font-size:12px;background-color:#fff;border-radius:100px;padding:0 10px;height:24px;line-height:24px;top:8px;font-weight:700}
.menu button{color:var(--btn_on_color);height:40px;padding:0 0 0 20px;line-height:40px;text-align:left;width:100%;position:relative;border-radius:5px}
.menu button:hover{background-color:var(--menu_off)}
.menu dd ul{display:none;}
.menu dd{display:none}
.menu dd.show{display:block}
.menu dd.show button{background-color:#384052}
.menu dd.show ul{display:block;animation-name: opacity;animation-duration: 0.2s;}
.menu dd.show button:hover{background-color:var(--menu_off)}
.menu dd svg{width:25px;}

.container{margin-left:260px;}
.header{overflow:hidden;overflow-x:auto;display:block;width:calc(100% - 260px);;margin-left:260px}
header{height:60px;line-height:60px;width:100%;min-width:1100px;border-bottom:1px solid #ccc;position:relative;padding-left:20px;}
header a{font-weight:700}
header .logout{position:absolute;top:0;right:20px;color:#3B4894;padding-top:15px}
header span {font-size:12px;margin-right:10px;}
header span:before{content:'＞';margin-right:10px;}
header span:first-child{margin-left:0;}
header span:first-child:before{content:'';margin-right:0;}
header ul{margin-left:0;width:1000px}
header li{display:inline-block;}
header li button{color:var(--btn_on);padding:0 10px;margin-right:10px;}
header li button:hover{background-color:var(--btn_on);color:#fff}
header li.show button{background-color:var(--btn_on);color:#fff}
header li.show a{color:#fff}
header li a:hover{color:#fff}
header li button:hover a{color:#fff}
input[type=text],input[type=password]{border:1px solid #ccc;width:100%;height:40px;border-radius:5px;padding:10px}
textarea{border:1px solid #ccc;width:100%;height:140px;border-radius:5px;padding:10px}

.notfound{background-color:var(--btn_on);width:100%;height:100%;position:absolute}
.notfound div{position:absolute;left:50%;top:40%;transform:translate(-50%,-50%);text-align:center;}
.notfound h1{color:var(--btn_on_color);font-size:50px}
.notfound p{color:var(--btn_on_color);font-size:20px;margin-top:20px;}

.login{width:400px;padding:40px 20px;border-radius:5px;background-color:#fff;border:1px solid #ccc;position:absolute;top:40%;left:50%;transform:translate(-50%,-50%)}
.login caption{display:inline-block;}
.login div{position:relative;margin-bottom:20px;}
.login label{font-weight:700;font-size:12px;color:#838383}
.login button{background-color:var(--btn_on);color:var(--btn_on_color);border-radius:5px;margin-top:20px;font-size:16px;font-weight:700;width:100%;}

.btn-primary{font-size:14px;background-color:var(--btn_on);border-radius:5px;height:40px;color:#fff;display:inline-block;line-height:40px}
.btn-danger{font-size:14px;background-color:var(--danger);border-radius:5px;height:40px;color:#fff;display:inline-block;line-height:40px}
.btn-warning{font-size:14px;background-color:var(--warning);border-radius:5px;height:40px;color:#fff;display:inline-block;line-height:40px}
.btn-info{font-size:14px;background-color:var(--info);border-radius:5px;height:40px;color:#fff;display:inline-block;line-height:40px}
.btn-success{font-size:14px;background-color:var(--success);border-radius:5px;height:40px;color:#fff;display:inline-block;line-height:40px}
.btn-off{font-size:14px;background-color:var(--btn_off);border-radius:5px;height:40px;color:#fff;display:inline-block;line-height:40px}
.btn-small{height:30px;padding:0 20px;line-height:30px}

.text-primary{color:var(--btn_on);}
.text-danger{color:var(--danger);}
.text-warning{color:var(--warning);}
.text-info{color:var(--info);}
.text-success{color:var(--success);}
.text-off{color:var(--btn_off);}

.checkbox {position:relative;display:inline-block;margin-right:20px;}
.checkbox input{border:1px solid var(--btn_off);width:20px;height:20px;vertical-align:top;background-color:#fff;position:relative;}
.checkbox input:checked{border:1px solid var(--btn_on);background-color:var(--btn_on);}
.checkbox input:checked:before{content:'√';font-size:12px;font-weight:700;position:absolute;top:1px;left:5px;color:#fff;}
.checkbox span{margin:1px 0 0 5px;display:inline-block;}

.radiobox {position:relative;display:inline-block;margin-right:20px;}
.radiobox input{border:1px solid var(--btn_off);width:20px;height:20px;vertical-align:top;border-radius:20px;background-color:#fff;position:relative;}
.radiobox input:checked{border:1px solid var(--btn_on);background-color:var(--btn_on);}
.radiobox input:checked:before{content:'●';font-size:8px;font-weight:700;position:absolute;top:2px;left:5px;color:#fff}
.radiobox span{margin:1px 0 0 5px;display:inline-block;}

.togglebox {position:relative;display:inline-block;margin-right:20px;}
.togglebox input{border:1px solid var(--btn_off);width:34px;height:20px;vertical-align:top;border-radius:20px}
.togglebox input:before{content:'';background-color:var(--btn_off);width:14px;height:14px;position:absolute;left:4px;top:3px;border-radius:14px}
.togglebox input:checked{border:1px solid var(--btn_on);}
.togglebox input:checked:before{background-color:var(--btn_on);left:unset;right:4px}

.filebox {position:relative;}
.filebox label{position:relative;display:inline-block;cursor:pointer}
.filebox input{position:absolute;z-index:2;width:100%;height:40px;opacity:0;}
.filebox span{background-color:#333333;display:block;color:#fff;padding:0 20px;height:40px;border-radius:5px;text-align:center;line-height:40px;cursor:pointer}
.filebox p{display:inline-block;margin-left:10px;font-weight:700;}
.filebox button{position:relative;font-size:0px;width:20px;height:20px;background-color:var(--danger);padding:0;border-radius:20px;margin-left:5px;vertical-align: top;margin-top:11px;}
.filebox button::before{content:'x';color:#fff;position:absolute;left:0;top:-1px;font-size:14px;width:20px;}
.filebox button.show{display:inline-block}
.filebox button.hide{display:none}
.filebox._multi div{display:inline-block}

.head._02{height:40px;}
.head._02 span{margin-left:5px;}

.fileimgbox {position:relative;cursor:pointer;display:inline-block}
.fileimgbox label{position:relative;display:inline-block}
.fileimgbox input{position:absolute;z-index:2;width:100%;height:100%;opacity:0;left:0;top:0;}
.fileimgbox img{object-fit: contain;background-color:#f1f1f1}
.fileimgbox img.max1920{width:1920px;max-width:100%;}

.categorybox {position:relative;margin-top:10px;display:inline-block}
.categorybox li{position:relative;display:block;margin-bottom:10px;padding-right:30px;}
.categorybox button{position:absolute;right:0;font-size:0px;width:20px;height:20px;background-color:var(--danger);padding:0;border-radius:20px;margin-left:5px;vertical-align: top;}
.categorybox button::before{content:'x';color:#fff;position:absolute;left:0;top:-1px;font-size:14px;width:20px;}
.click{cursor:pointer}

.search .wrap_search{border:1px solid #ccc;border-radius:5px;padding:20px;background-color:#f3f2f2;margin-bottom:20px}
.search .search_group{width:50%;display:inline-block;height:42px;margin-bottom:10px;vertical-align: top;}
.search .title{width:100px;display:inline-block;font-weight:bold;text-align:right;padding-right:10px;height:40px;line-height:40px;}
.search .box_search{width:calc(100% - 100px);display:inline-flex}
.search button{margin:0 5px}
.box_search select{margin-left:5px;display:inline-block;width:auto}
.box_search select:first-child{margin-left:0;}
.box_wave{text-align:center;width:30px;padding-top:8px;display:inline-block}

.x{font-weight:700;font-size:16px;color:var(--warning)}
.o{font-weight:700;font-size:12px;color:var(--success)}

.text-center{text-align: center !important;margin-bottom:20px}
.date{display:inline-block;position:relative}
.date i{position:absolute;right:10px;top:9px;font-size:16px;}
.hour{display:inline-block;width:auto;margin-left:5px;}
.min{display:inline-block;width:auto;margin-left:5px;}

table{width:100%;border-top:1px solid #8d8d8d}
tbody a{font-weight:700;color:var(--info)}
tbody a.link{color:var(--danger)}
th{border-bottom:1px solid #ccc;padding:12px 20px;background-color:#f1f1f1;font-weight:700}
td{border-bottom:1px solid #ccc;padding:12px 20px;text-align:center}
tfoot td{text-align:center;border:0;padding-top:24px;position:relative}
tfoot a{display:inline-block;width:30px;height:20px;}
tfoot a.active{color:var(--btn_on);font-weight:700}
tfoot button{margin:0 5px;float:right}
tfoot button:first-child{position:absolute;left:0px;top:25px;}
table.detail{margin-bottom:40px}
table.detail th{text-align:right;padding:8px 20px;height:56px;}
table.detail thead th{background-color:#e0e0e0;text-align:center}
table.detail thead th.left{text-align:left}
table.detail td{text-align:left;padding:8px 20px;}
table.detail tfoot td{text-align:center;border:0;padding:24px 0 0 0;}
table.detail small{font-size:12px}
table.detail.hide{display:none}
table.detail.onlybtn {border:0}
table.detail h2{font-weight:700;font-size:16px;}
table.detail h2 small{margin-left:10px}
.text-small{line-height:14px;padding-top:5px;padding-bottom:5px}

tr.prev{background-color:rgba(249,177,21,0.1)}

.flex select{display:inline-block;width:auto;margin-left:10px;}
.flex select:first-child{margin-left:0}
.flex input{display:inline-block;width:auto;margin-left:10px;width:100%;max-width:500px;}
.flex input:first-child{margin-left:0}
.flex button{display:inline-block;width:auto;margin-left:10px;}
.flex div{display:inline-block;width:auto;margin-left:10px;}

.text-flex input[type=text]{display:inline-block;width:calc(100% - 200px);margin-left:10px;margin-right:10px}
.text-flex input[type=text]:first-child{margin-left:0}
.file-flex .filebox{display:inline-block;margin-left:10px}

.tab {border-bottom:1px solid #ccc;vertical-align: bottom;margin-bottom:10px;position:relative;}
.tab button{border:1px solid #ccc;color:var(--btn_on);border-radius:5px 5px 0 0;margin-right:5px;margin-bottom:-1px}
.tab button.active{border:1px solid var(--btn_on);background-color:var(--btn_on);color:#fff}
.tab_box{padding:0px 0;width:100%;vertical-align:top;display:flex;}
.tab_category{width:30%;min-width:700px;position:relative}
.tab_category option.hide{display:none}
.tab_category dt button{position:absolute;right:10px;top:8px;}
.tab_detail{width:calc(70% - 10px);margin-left:10px;}
.tab_detail table{border-top:1px solid #ccc}


.wrap{padding:20px 20px 20px 20px;}
.box{border:1px solid #ccc;border-radius:5px;width:100%;margin:20px auto;margin-top:0;padding:20px;background-color:#fff;}
.normal .show{display:block}
.normal .hide{display:none}
.normal tr.show{display:table-row}

.banner li{margin-bottom:20px}
.banner h2{margin-top:2px;margin-bottom:10px}
.banner img {display:inline-block}
.banner p{display:inline-block;vertical-align: top;margin-left:10px;width:100%;max-width:500px}
.banner button{margin-right:5px;}
.banner div{margin-top:11px;}
.banner i{background-color:var(--success);font-size:16px;color:#fff;width:30px;display:inline-block;text-align:center;vertical-align: top;border-radius:30px;height:30px;line-height:30px;margin:60px 10px 0 0}

.dragndrop dl{display:inline-block;width:100%;}
.dragndrop dt{border:1px solid #ccc;background-color:#f1f1f1;line-height:52px;height:55px;font-weight:700;text-align:center;font-size:16px;position:relative}
.dragndrop dt button{position:absolute;right:10px;top:7px;}
.dragndrop select{display:inline-block;width:auto;margin-left:10px}
.dragndrop dd{height:55px;display:none;border:1px solid #ccc;border-top:0;padding-top:7px;}
.dragndrop dd.show{display:block}
.dragndrop i{background-color:var(--success);font-size:16px;color:#fff;width:30px;display:inline-block;text-align:center;vertical-align: top;border-radius:30px;height:30px;line-height:30px;margin:6px 10px 0 10px}
.dragndrop i.bean{background:transparent}
.dragndrop div{position:relative;display:inline-block;vertical-align: top;margin-left:10px;width:calc(100% - 100px);}
.dragndrop span{margin-top:12px;display: inline-block;}
.dragndrop input[type=text]{width:calc(100% - 200px);}
.dragndrop button{margin-left:10px}
.dragndrop div.radio input[type=text]{width:calc(100% - 370px);margin-right:10px}
.dragndrop div.radio input[type=radio]{vertical-align: bottom;}

.agree{padding:20px 0}
.manager table.detail{width:100%;max-width:700px}


.paging li{display:inline-block}
.paging li:first-child{display:none}

.paging li:last-child{display:none}
.paging li.active a{font-weight:bold}

.text{color:#fff}
@keyframes opacity {
  0% {opacity: 0;}
  100% {opacity: 1;}
}


.sun-editor .se-dialog-tabs button{height:auto !important;border-radius:0 !important;}


.excel_scroll {overflow:hidden;overflow-x:auto;width:100%;}
.excel_scroll table{min-width:2000px;max-width:100%;}